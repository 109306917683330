import React, { useContext, useState, useEffect } from 'react'
import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import SliderRotator from '../organisms/SliderRotator'
import TestQuestions from '../organisms/TestQuestions'
import MajorAwardBanner from '../atoms/MajorAwardBanner'
import CustomModal from '../molecules/CustomModal'
import { getDateNow } from '../../utils/functions'

function Mechanic() {
  const { context } = useContext(StateController);
  const [pushModal, setPushModal] = useState(true);
  const [pushModalSupermarket, setPushModalSupermarket] = useState(false);
  const [modalSuperPower, setModalSuperPower] = useState(true);

  useEffect(() => {
    if (context && context.tracing && context.tracing.extra === "1") {
      setPushModalSupermarket(true)
    }
  }, [context])


  return (
    <div className='mc-page mechanic inner slow_ani'>
      
      <Container>
        <h1>¿Cómo ganar?</h1>
        <h2>Sigue estos pasos y déjale el resto a tu Tarjeta de Débito Mastercard PacifiCard:</h2>

        <div className='mc-section-login-slider'>
          <SliderRotator
            mobileItemsToShow={1}
            desktopItemsToShow={3}
            style={1}
            ext={true}
            list={
              [
                {
                  id: 1,
                  image: "mechanic_slide_1.png",
                  title: "<span>Regístrate con el código</span> que te enviamos por correo o mensaje de texto.",
                  titleDesktop: "<span>Regístrate con el código</span> que te enviamos por correo o mensaje de texto."
                },
                {
                  id: 2,
                  image: "mechanic_slide_2.png",
                  title: 'Usa tu Tarjeta de Débito Mastercard PacifiCard para pagar "TODO" entre el <span>2 de septiembre y el 30 de noviembre de 2024.</span>',
                  titleDesktop: 'Usa tu Tarjeta de Débito Mastercard PacifiCard para pagar "TODO" entre el <span>2 de septiembre y el 30 de noviembre de 2024.</span>',
                },
                {
                  id: 3,
                  image: "mechanic_slide_3.png",
                  title: 'Consulta tu meta y los comercios que te ayudarán a alcanzarla en la sección <span>"Meta y progreso".</span>',
                  titleDesktop: 'Consulta tu meta y los comercios que te ayudarán a alcanzarla en la sección <span>"Meta y progreso".</span>'
                },
                {
                  id: 4,
                  image: "mechanic_slide_4.png",
                  title: "<span>Cumple tu meta, canjea tu bono</span> y participa por un premio especial al final de la campaña.",
                  titleDesktop: "<span>Cumple tu meta, canjea tu bono</span> y participa por un premio especial al final de la campaña."
                },
              ]
            }
          />
        </div>

        <MajorAwardBanner context={context} />

        <div className='mc-section-testForm'>
          <TestQuestions context={context} />
        </div>
      </Container>
      {
        (context?.redemtions?.length > 0)  && (context?.tracing && context?.tracing?.extra !== '1') && 
          <CustomModal
            title="¡Lo lograste! <br /> Canjeaste tu bono"
            desc="¡Ganas al ser el cliente que más veces cumple su meta o al acumular la mayor facturación al final de la campaña!"
            subDescription
            cta="Aceptar"
            icon={false}
            push
            context={context}
            image="img_push_modal.png"
            primaryAction={
              {
                action: setPushModal,
                buttonText: 'Quiero más premios',
                loading: false
              }
            }
            show={pushModal} setShowModal={setPushModal}
          />
      }

      {/* <CustomModal
        title="Tus compras en Hipermarket te dan más beneficios"
        desc="<strong>Del 15 al 10 de noviembre de 2024</strong>, al realizar una compra* en <strong>Hipermarket</strong> con tu PacifiCard, ¡recibirás un bono* de este comercio!"
        subDescription
        cta="Aceptar"
        icon={false}
        push
        context={context}
        image="img_push_modal.png"
        show={pushModalSupermarket} setShowModal={setPushModalSupermarket}
        isSupermarket={true}
      /> */}
      

      {
        (context?.tracing?.extra && context?.tracing?.extra === '1' && modalSuperPower && getDateNow() >= '2024-10-10' && getDateNow() <= '2024-10-13') && 
          <CustomModal
          title="¡Tus compras tienen superpoderes! 🛍️"
          desc={`<strong>Solo del 11 al 13 de Octubre</strong>, cada compra que hagas se multiplicará por dos. <br /> <br /> ¡No dejes pasar esta oportunidad de <strong>cumplir tu meta más rápido y GANAR un bono!</strong>`}
          cta="Quiero más premios"
          icon={false}
          push
          context={context}
          image="modal-superPower.png"
          modalSuperPower
          primaryAction={
            {
              action: setModalSuperPower,
              buttonText: 'Quiero más premios',
              loading: false
            }
          }
          show={modalSuperPower} setShowModal={setModalSuperPower}
        />
      }
    </div>
  )
}
export default Mechanic