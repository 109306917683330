import React from 'react'

// import Container from '../../layouts/Container/Container'
import LoginForm from '../organisms/LoginForm'
import SliderRotator from '../organisms/SliderRotator'

function Login({
  autologin,
  hash,
  setGlobalLoader
}) {
  console.log("login")

  return (
    <div className='mc-page login slow_ani'>

      <div className='mc-section-login-group-1'>
      <h3 className='h3-mobile '>Con tu Tarjeta de Débito Mastercard PacifiCard, <span>más la usas, más ganas.</span></h3>
      <div className='mc-section-login-form'>
          <LoginForm
            title="¡Comienza a ganar!"
            type="credentials"
            fields={[
              {field: "code", label: "Introduce el código recibido por correo o SMS", placeholder: "XHL123...", maxlength: 8, separated: false },
              {field: "onlyText", text: "<strong>Nota</strong>: Revisa tu bandeja de entrada o spam, y/o SMS, para encontrar tu código de ingreso."},
              {field: "terms", label: "He leído y acepto los",termsText: "términos y condiciones",termsLink: "/terminos-y-condiciones"} // DO NOT CHANGE THIS LINE
            ]}
            buttonText="Ingresar"
            background="#fff"
            autologin={autologin}
            hash={hash}
            setGlobalLoader={setGlobalLoader}
          />
        </div>

        <div className='mc-section-login-slider'>
        <h3 className='h3-desktop'>Con tu Tarjeta de Débito Mastercard PacifiCard, <span>más la usas, más ganas.</span></h3>
          <SliderRotator
            mobileItemsToShow={1}
            desktopItemsToShow={3}
            style={1}
            ext={true}
            list={
              [
                {
                  id: 1,
                  image: "mechanic_slide_1.png",
                  title: "<span>Regístrate con el código</span> que te enviamos por correo o mensaje de texto.",
                  titleDesktop: "<span>Regístrate con el código</span> que te enviamos por correo o mensaje de texto."
                },
                {
                  id: 2,
                  image: "mechanic_slide_2.png",
                  title: 'Usa tu Tarjeta de Débito Mastercard PacifiCard para pagar "TODO" entre el <span>2 de septiembre y el 30 de noviembre de 2024.</span>',
                  titleDesktop: 'Usa tu Tarjeta de Débito Mastercard PacifiCard para pagar "TODO" entre el <span>2 de septiembre y el 30 de noviembre de 2024.</span>',
                },
                {
                  id: 3,
                  image: "mechanic_slide_3.png",
                  title: 'Consulta tu meta y los comercios que te ayudarán a alcanzarla en la sección <span>"Meta y progreso".</span>',
                  titleDesktop: 'Consulta tu meta y los comercios que te ayudarán a alcanzarla en la sección <span>"Meta y progreso".</span>'
                },
                {
                  id: 4,
                  image: "mechanic_slide_4.png",
                  title: "<span>Cumple tu meta, canjea tu bono</span> y participa por un premio especial al final de la campaña.",
                  titleDesktop: "<span>Cumple tu meta, canjea tu bono</span> y participa por un premio especial al final de la campaña."
                },
                {
                  id: 5,
                  space: true
                }
              ]
            }
          />
        </div>

      </div>

      <div className='mc-section-login-group-2'>
        <div className='mc-section-login-banner'>
          <div className='mc-section-login-banner__image'>
            <div className='mc-section-login-banner__image--text'>
              <p>Tu Tarjeta de Débito< br/>Mastercard PacifiCard</p>
              <p><strong>¡Más la usas, más ganas!</strong></p>
            </div>
            <img src={`/assets/images/login_banner.png`} />
          </div>
          <div className='mc-section-login-banner__labels'>
            <div>Alcanza tu meta y gana premios instantáneos o redime bonos.</div>
            <p>Participa del xx de noviembre de 2023 al xx de enero de 2024.</p>
          </div>
        </div>

        <div className='mc-section-login-slider2'>
          <h3>Razones para usar y aprovechar tu Tarjeta de Débito Mastercard PacifiCard</h3>
          <SliderRotator
            mobileItemsToShow={1}
            desktopItemsToShow={2}
            style={2}
            list={
              [
                {
                  id: 1,
                  image: "login_slide_v2_1.png",
                  title: "Con su tecnología “Contact Less” logras consumos más rápidos y seguros.",
                },
                {
                  id: 2,
                  image: "login_slide_v2_1.png",
                  title: "Con su tecnología “Contact Less” logras consumos más rápidos y seguros.",
                },
                {
                  id: 3,
                  image: "login_slide_v2_1.png",
                  title: "Con su tecnología “Contact Less” logras consumos más rápidos y seguros.",
                },
                {
                  id: 4,
                  image: "login_slide_v2_1.png",
                  title: "Con su tecnología “Contact Less” logras consumos más rápidos y seguros.",
                }
              ]
            }
          />
        </div>
      </div>


    </div>
  )
}
export default Login